import React, { Component, useState, useEffect } from "react";
import HeadBar from "./common/HeadBar";
import "../style/order.scss";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import { Radio } from "antd";
import Reload from "./common/Reload";
import * as SubmitPayAPI from "../apis/SubmitPayAPI";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Popup from "./common/Popup";
import Cookie from "react-cookies";
import * as GetPixelAPI from "../apis/GetPixelAPI";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reloadingShow: false,
      orderDetail: {},
      PlanList: [
        {
          title: "日本郵便",
          checked: true,
          value: null,
        },
        {
          title: "宅急便",
          checked: false,
          value: null,
        },
      ],
      contactType: "日本郵便",
      test_drive_package: 0,
      reloadingShow: false,
      payment_intent_id: null,
      clientSecret: "",
      stripePromise: "",
      userDetails: {},
      count: 3,
      url: "",
      amount: "",
    };
    this.changeChecked = this.changeChecked.bind(this);
    this.Upload = this.Upload.bind(this);
    this.toggleReload = this.toggleReload.bind(this);
    this.removeAllVideos = this.removeAllVideos.bind(this);
    this.createVideo = this.createVideo.bind(this);
    this.routePlan = this.routePlan.bind(this);
    this.routePage = this.routePage.bind(this);

    this.receiveVideoRef = React.createRef();

    this.clickToPay = this.clickToPay.bind(this);
    this.toggleReload = this.toggleReload.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    this.setState({ orderDetail }, () => {
      if (orderDetail.userSelfPictureOrVideo) {
        this.removeAllVideos();
        this.createVideo(orderDetail.userSelfPictureOrVideo);
      }
    });
    let { PlanList, contactType, test_drive_package } = this.state;
    if (orderDetail) {
      if (orderDetail.contactType) {
        PlanList.map((item, index) => {
          item.checked = false;
          if (item.title === orderDetail.contactType) {
            item.checked = true;
          }
        });
        this.setState({
          PlanList,
          contactType: orderDetail.contactType,
        });
      }
      if (orderDetail.payment_intent_id) {
        this.setState({ payment_intent_id: orderDetail.payment_intent_id });
      }
      orderDetail.contactType = contactType;
      orderDetail.test_drive_package = test_drive_package;
      localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    }
    if (Cookie.load("prohibitd_purchase")) {
      console.log("禁止购买------");
      this.setState({ count: 0 });
    }
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    GetPixelAPI.getPixel().then((res) => {
      var data = res.data.data;
      if (data) {
        let { PlanList } = this.state;
        PlanList[0].value = data.amount;
        PlanList[1].value = data.amount_fast;
        this.setState({ amount: data.amount, PlanList });
      }
    });
  }

  changeChecked(index) {
    let { PlanList } = this.state;
    PlanList.map((item, itemIndex) => {
      item.checked = false;
      if (index === itemIndex) {
        item.checked = true;
        this.setState({ amount: item.value, test_drive_package: index }, () => {
          let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
          orderDetail.amount = item.value;
          orderDetail.test_drive_package = index;
          localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
        });
      }
    });
    this.setState({ PlanList });
  }

  Upload(type) {
    let { history, location } = this.props;
    const search = location.search;
    switch (type) {
      case "model":
        history &&
          history.push({
            pathname: "/",
            search: location.search,
          });
        break;
      case "customer":
        history &&
          history.push({
            pathname: "/Customer",
            search: location.search,
          });
        break;
      case "card":
        history &&
          history.push({
            pathname: "/Info",
            search: location.search,
          });
        break;
    }
  }

  toggleReload() {
    this.setState({ reloadingShow: !this.state.reloadingShow });
  }

  // 移除节点下的所有视频元素
  removeAllVideos() {
    const videoElements = document.querySelectorAll("video");
    videoElements.forEach((video) => {
      video.remove();
    });
  }

  //创建新的元素
  createVideo(src) {
    const videoElement = document.createElement("video");
    videoElement.src = BASE_URL + src;
    // videoElement.autoplay = true;
    videoElement.controls = true;
    videoElement.type = "video/mp4";
    videoElement.playsInline = true;
    this.receiveVideoRef.current = videoElement;
    document.querySelector(".video-box").appendChild(videoElement);
  }

  routePlan() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Plan",
        search: location.search,
      });
  }

  routePage() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Fail",
        search: location.search,
      });
  }

  clickToPay() {
    let { payment_intent_id, count, test_drive_package } = this.state;
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    orderDetail.test_drive_package = test_drive_package;
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    console.log(count);
    if (count === 0) {
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: "本日の購入時間が到着しました!",
        })
      );
      // 24小时内只允许点三次
      let time = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
      let value = "Prohibitd purchase";
      Cookie.save("prohibitd_purchase", value, { expires: time, path: "/" });
      return;
    }
    count--;
    console.log(count);
    this.setState({ count });
    let ad = "";
    if (localStorage.getItem("ad")) {
      ad = JSON.parse(localStorage.getItem("ad")) || "cutting";
      console.log(ad);
    }
    orderDetail.ad = ad;
    orderDetail.success_url = "/Success";
    orderDetail.cancel_url = "/Fail";
    orderDetail.payment_intent_id = payment_intent_id;
    orderDetail.type = 10;
    orderDetail.curcrency = 2;
    this.toggleReload();
    SubmitPayAPI.submit(orderDetail)
      .then((res) => {
        console.log(res);
        this.toggleReload();
        var data = res.data.data.data;
        console.log(data);
        if (data !== null) {
          let stripePromise = loadStripe(data.api_publishable_key);
          // 自动滑到底部
          setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
          }, 2000);
          let userDetails = {
            name: orderDetail.firstName,
            email: orderDetail.email,
            address: {
              // city: orderDetail.city,
              state: orderDetail.state,
              country: orderDetail.country,
              postal_code: orderDetail.zipCode,
              line1: orderDetail.address,
            },
          };
          this.setState({
            stripePromise,
            payment_intent_id: data.payment_intent_id,
            clientSecret: data.clientSecret,
            userDetails,
            url: `${BASE_URL}/success?payment_intent_id=${data.payment_intent_id}&subscriptionId=${data.subscriptionId}&status=succeeded&c_site_url=${data.c_site_url}&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`,
            // url: `http://localhost:3001/success?payment_intent_id=${data.payment_intent_id}&status=succeeded&c_site_url=${data.c_site_url}&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`,
          });

          orderDetail.payment_intent_id = data.payment_intent_id;
          localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
        } else {
          this.clickToPay();
        }
      })
      .catch((err) => {
        this.toggleReload();
      });
  }

  toggleReload() {
    this.setState({ reloadingShow: !this.state.reloadingShow });
  }

  render() {
    const {
      orderDetail,
      PlanList,
      clientSecret,
      stripePromise,
      userDetails,
      url,
      amount,
    } = this.state;

    const appearance = {
      theme: "stripe",
    };
    const options = {
      clientSecret,
      appearance,
      locale: "ja",
    };

    return (
      <React.Fragment>
        <Reload {...this.props} reloadingShow={this.state.reloadingShow} />
        <div className="order-page">
          <HeadBar {...this.props} />
          <div className="order-box">
            <h1>注文情報の確認</h1>
            <div className="table">
              <div className="head">ブランドモデル</div>
              <div className="table-li">
                <div className="title">ブランドモデル</div>
                <div className="content">{orderDetail.vehicleModel}</div>
              </div>
              <div className="upload-row">
                <div className="upload" onClick={() => this.Upload("model")}>
                mise à jour
                </div>
              </div>
            </div>
            <div className="table">
              <div className="head">ご注文について</div>
              <div className="table-li">
                <div className="title">prénom</div>
                <div className="content">{orderDetail.firstName}</div>
              </div>
              <div className="table-li">
                <div className="title">nom de famille</div>
                <div className="content">{orderDetail.lastName}</div>
              </div>
              <div className="table-li">
                <div className="title">e-mail</div>
                <div className="content">{orderDetail.email}</div>
              </div>
              {/* <div className="table-li">
                <div className="title">ドライバーのEメールアドレス</div>
                <div className="content">{orderDetail.driveEmail}</div>
              </div> */}
              <div className="table-li">
                <div className="title">téléphone</div>
                <div className="content">{orderDetail.phone}</div>
              </div>
              {/* <div className="table-li">
                <div className="title">運転手の電話番号</div>
                <div className="content">{orderDetail.drivePhone}</div>
              </div> */}
              <div className="table-li">
                <div className="title">province</div>
                <div className="content">{orderDetail.state}</div>
              </div>
              <div className="table-li">
                <div className="title">city</div>
                <div className="content">{orderDetail.city}</div>
              </div>
              <div className="table-li">
                <div className="title">adresse</div>
                <div className="content">{orderDetail.address}</div>
              </div>
              <div className="table-li">
                <div className="title"> code postal</div>
                <div className="content">{orderDetail.zipCode}</div>
              </div>
              <div className="table-li">
                <div className="title">pays</div>
                <div className="content">{orderDetail.country}</div>
              </div>
              <div className="upload-row">
                <div className="upload" onClick={() => this.Upload("customer")}>
                mise à jour
                </div>
              </div>
            </div>
            {/* <div className="table">
              <div className="head">アイデンティティ情報</div>
              <div className="table-li">
                <div className="title">書類表面</div>
                <div className="content">
                  <img src={BASE_URL + orderDetail.documentFront} alt="" />
                </div>
              </div>
              {orderDetail.identityType === "PassPort" ? (
                ""
              ) : (
                <div className="table-li">
                  <div className="title">裏面</div>
                  <div className="content">
                    <img src={BASE_URL + orderDetail.documentBack} alt="" />
                  </div>
                </div>
              )}

              <div className="table-li">
                <div className="title">セルフィー</div>
                <div className="content video-box">
                  <video
                    ref={this.receiveVideoRef}
                    src={BASE_URL + orderDetail.userSelfPictureOrVideo || ""}
                    alt=""
                    playsInline={true}
                    autoPlay={true}
                  />
                </div>
              </div>
              <div className="upload-row">
                <div className="upload" onClick={() => this.Upload("card")}>
                  アップデイト
                </div>
              </div>
            </div> */}
            <div className="choose-box">
              <h2>宅配業者の選択</h2>
              {PlanList.map((item, index) => {
                return (
                  <div
                    className="choose-li"
                    key={index}
                    onClick={() => this.changeChecked(index)}
                  >
                    <Radio checked={item.checked}></Radio> {item.title} JP￥
                    {item.value}
                  </div>
                );
              })}
              {clientSecret !== "" ? (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm userDetails={userDetails} url={url} />
                </Elements>
              ) : (
                <div className="btn" onClick={this.clickToPay}>
                  クレジットカードでのお支払い
                </div>
              )}
              <div className="cancel-btn" onClick={this.routePage}>
                申し込みの取り消し、未払い
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Order;
