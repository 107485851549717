import React from "react";

const RePhoto = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#343434",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="7537"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M819.64 214.12h-85.26c-0.96-0.02-2.54-0.22-4.15-0.5l-50.8-67.15-4.02-4.52c-15.84-15.14-33.45-22.49-53.83-22.49h-199.3c-17.18 0-43.26 5.27-60.22 20.12l-62.02 74.16c-1.27 0.2-2.46 0.35-3.18 0.37h-92.48c-77.29 0-140.17 59.34-140.17 132.27v425.89c0 72.93 62.88 132.25 140.17 132.25h615.26c77.29 0 140.15-59.32 140.15-132.25V346.39c0-72.93-62.87-132.27-140.15-132.27z m64.9 558.16c0 31.43-29.12 57-64.9 57H204.38c-35.8 0-64.92-25.57-64.92-57V346.39c0-31.45 29.12-57.02 64.92-57.02h92.48c3.34 0 33.14-0.44 50.85-15.95l64.19-76.79c2.85-0.97 7.39-1.91 10.38-1.91l200.55 1.65 53.48 70.67 4.06 4.56c18.44 17.56 52.45 17.76 53.88 17.76h85.39c35.79 0 64.9 25.57 64.9 57.02v425.9z"
      fill={color}
      p-id="7538"
    ></path>
    <path
      d="M598.97 377.74l38.9-1.7c20.76-0.9 36.85-18.48 35.93-39.24-0.92-20.76-19.23-36.69-39.24-35.93l-131.77 5.77c-19.47 0.85-35.09 16.46-35.93 35.93l-5.77 131.77c-0.9 20.76 15.17 38.34 35.93 39.24 0.57 0.02 1.12 0.04 1.69 0.04 20.01 0 36.67-15.78 37.55-35.97l1.96-44.74c0.78 0.3 1.33 0.92 2.14 1.18 56.78 17.84 94.94 69.85 94.94 129.42 0 74.77-60.84 135.61-135.61 135.61s-135.61-60.84-135.61-135.61c0-39.11 16.9-76.33 46.37-102.12 15.63-13.69 17.21-37.46 3.53-53.09-13.72-15.65-37.48-17.23-53.09-3.53-45.78 40.09-72.05 97.95-72.05 158.74 0 116.27 94.59 210.86 210.86 210.86s210.86-94.59 210.86-210.86c-0.01-79.28-43.69-149.66-111.59-185.77z"
      fill={color}
      p-id="7539"
    ></path>
  </svg>
);

export default RePhoto;
