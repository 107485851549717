import React, { Component } from "react";
import HeadBar from "./common/HeadBar";
import "../style/selfie.scss";
import selfie from "../image/selfie2.gif";

class Selfie extends Component {
  constructor(props) {
    super(props);
    this.routeSelfieInfo = this.routeSelfieInfo.bind(this);
  }

  routeSelfieInfo() {
    let { location, history } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/SelfieInfo",
        search: location.search,
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="selfie-page">
          <HeadBar {...this.props} />
          <div className="selfie-box">
            <h1>カメラの準備</h1>
            <h2>明るい部屋で写真を撮る</h2>
            <h2>
              私たちは、ディープフェイクに対抗する高度なライブ検知を使用することで、セキュリティを優先しています。
            </h2>
            <div className="gif-box">
              <img src={selfie} alt="" />
            </div>
            <div className="btn" onClick={this.routeSelfieInfo}>
              次のページ
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Selfie;
