import React from "react";

const Approve = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#888888",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="28905"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M945.066667 238.933333c-155.733333-17.066667-290.133333-96-381.866667-213.333333C550.4 8.533333 531.2 0 512 0s-38.4 8.533333-51.2 25.6c-89.6 115.2-226.133333 196.266667-381.866667 213.333333-34.133333 4.266667-59.733333 34.133333-57.6 68.266667C59.733333 714.666667 264.533333 1024 512 1024s452.266667-309.333333 490.666667-716.8c2.133333-34.133333-23.466667-66.133333-57.6-68.266667z m-121.6 170.666667L507.733333 718.933333c-17.066667 17.066667-42.666667 17.066667-59.733333 0l-183.466667-179.2c-8.533333-8.533333-8.533333-21.333333 0-29.866666 8.533333-8.533333 21.333333-8.533333 29.866667 0l168.533333 164.266666c8.533333 8.533333 21.333333 8.533333 29.866667 0l300.8-294.4c8.533333-8.533333 21.333333-8.533333 29.866667 0 8.533333 8.533333 8.533333 21.333333 0 29.866667z"
      fill={color}
      p-id="28906"
    ></path>
  </svg>
);

export default Approve;
