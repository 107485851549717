import React, { Component } from "react";
import "../../style/common/payment.scss";
import Visa from "../../icon/Visa";
import MasterCard from "../../icon/MasterCard";
import Discover from "../../icon/Discover";
import American from "../../icon/American";
import JCB from "../../icon/JCB";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: "",
      cvc: "",
      expiry: "",
      errors: {},
      cardValid: false,
      cvcValid: false,
      expiryValid: false,
      cardType: "",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    // 针对输入进行格式化
    let processedValue = value;
    if (name === "cardNumber") {
      // 格式化卡号为 "0000 0000 0000 0000"
      processedValue = value.replace(/\D/g, "").slice(0, 16);
      processedValue = processedValue.replace(/(.{4})/g, "$1 ").trim(); // 每四位插入一个空格
    } else if (name === "expiry") {
      // 格式化为 MM/YY
      processedValue = value.replace(/\D/g, "").slice(0, 4); // 只保留数字，限制为4位
      if (processedValue.length >= 3) {
        processedValue = `${processedValue.slice(0, 2)}/${processedValue.slice(
          2
        )}`; // 格式化为 MM/YY
      }
    }

    this.setState({ [name]: processedValue,}, () => {
      // this.validateField(name, processedValue.replace(/\s|\//g, "")); // 去掉空格和斜杠再进行验证
      if (name === "cardNumber") {
        this.detectCardType(processedValue.replace(/\s/g, "")); // 去掉空格进行卡类型判断
      }
    });
  };

  detectCardType = (cardNumber) => {
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    const sanitizedNumber = cardNumber.replace(/\D/g, "");

    let cardType = "";

    if (/^4/.test(sanitizedNumber)) {
      cardType = "visa";
    } else if (
      /^5[1-5]/.test(sanitizedNumber) ||
      /^2[2-7]/.test(sanitizedNumber)
    ) {
      cardType = "mastercard";
    } else if (/^3[47]/.test(sanitizedNumber)) {
      cardType = "amex";
    } else if (/^6(?:011|5|4[4-9]|22)/.test(sanitizedNumber)) {
      cardType = "discover";
    } else if (/^35[2-8]/.test(sanitizedNumber)) {
      cardType = "jcb";
    }

    this.setState({ cardType });
  };

  validateField = (fieldName, value) => {
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    let errors = this.state.errors;
    let cardValid = this.state.cardValid;
    let cvcValid = this.state.cvcValid;
    let expiryValid = this.state.expiryValid;
    switch (fieldName) {
      case "cardNumber":
        cardValid = this.isValidCardNumber(value);
        errors.cardNumber = cardValid ? "" : "Invalid card number";
        break;
      case "cvc":
        cvcValid = this.isValidCVC(value);
        errors.cvc = cvcValid ? "" : "Invalid CVC";
        break;
      case "expiry":
        expiryValid = this.isValidExpiry(value);
        errors.expiry = expiryValid ? "" : "Invalid expiry date";
        break;
      default:
        break;
    }

    this.setState({
      errors,
      cardValid,
      cvcValid,
      expiryValid,
    });
  };

  isValidCardNumber = (cardNumber) => {
    const sanitizedNumber = cardNumber.replace(/\D/g, "");
    let sum = 0;
    let shouldDouble = false;

    for (let i = sanitizedNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(sanitizedNumber.charAt(i), 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  };

  isValidCVC = (cvc) => {
    return /^\d{3,4}$/.test(cvc);
  };

  isValidExpiry = (expiry) => {
    return /^(0[1-9]|1[0-2])\/\d{2}$/.test(expiry);
  };

  renderCardIcon = () => {
    const { cardType } = this.state;
    switch (cardType) {
      case "visa":
        return <Visa size={30} />;
      case "mastercard":
        return <MasterCard size={30} />;
      case "amex":
        return <American size={30} />;
      case "discover":
        return <Discover size={30} />;
      case "jcb":
        return <JCB size={30} />;
      default:
        return (
          <div className="default-icons">
            <Visa size={24} />
            <MasterCard size={24} />
            <American size={24} />
            <Discover size={24} />
            <JCB size={24} />
          </div>
        );
    }
  };

  handleBlur = (e) => {
    const { name, value } = e.target;
    this.validateField(name, value);
  };

  render() {
    const { errors, cardNumber, cvc, expiry } = this.state;
    return (
      <div className="payment-container">
        <div className="card-title">Card Infomation</div>
        <div className="card-row">
          <div className="full">
            <div className="input-box">
              <input
                type="text"
                name="cardNumber"
                placeholder="0000 0000 0000 0000"
                value={this.state.cardNumber}
                onChange={this.handleInputChange}
                maxLength="19" // 限制输入长度为19个字符（包括空格）
                onBlur={this.handleBlur}
                className={errors.cardNumber ? "input-error" : ""}
              />
              <div className="card-icon">{this.renderCardIcon()}</div>
            </div>
            {errors.cardNumber && (
              <div className="error-message">{errors.cardNumber}</div>
            )}
          </div>
        </div>
        <div className="card-row">
          <div className="half">
            <input
              type="text"
              name="expiry"
              placeholder="MM/YY"
              value={this.state.expiry}
              onChange={this.handleInputChange}
              maxLength="5" // 限制长度为5，包括斜杠
              onBlur={this.handleBlur}
              className={errors.expiry ? "input-error" : ""}
            />{" "}
            {errors.expiry && (
              <div className="error-message">{errors.expiry}</div>
            )}
          </div>
          <div className="half">
            <input
              type="text"
              name="cvc"
              placeholder="CVC"
              value={this.state.cvc}
              onChange={this.handleInputChange}
              maxLength="4" // 限制长度为4
              onBlur={this.handleBlur}
              className={errors.cvc ? "input-error" : ""}
            />{" "}
            {errors.cvc && <div className="error-message">{errors.cvc}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default Payment;
