import React from "react";

const ShippingCart = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#8a8a8a",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="14393"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M886.9888 716.8c-9.0112-42.98752-39.95648-78.45888-82.00192-94.04416a133.95968 133.95968 0 0 0-125.31712 17.24416V363.39712c0-17.65376 9.29792-34.05824 24.63744-43.4176a53.63712 53.63712 0 0 1 50.7904-2.6624l157.42976 74.62912A51.0976 51.0976 0 0 1 942.08 438.02624V665.6c0 28.2624-23.49056 51.2-52.46976 51.2h-2.64192z m-446.09536 0c-12.51328-59.55584-66.2528-102.31808-128.57344-102.31808-62.32064 0-116.06016 42.76224-128.57344 102.31808h-28.8768C125.89056 716.8 102.4 693.8624 102.4 665.6v-409.6C102.4 227.7376 125.89056 204.8 154.86976 204.8h419.84c28.99968 0 52.49024 22.9376 52.49024 51.2v409.6c0 28.2624-23.49056 51.2-52.49024 51.2h-133.81632z m-128.57344 102.4c43.47904 0 78.72512-34.4064 78.72512-76.8 0-42.41408-35.24608-76.8-78.72512-76.8-43.47904 0-78.72512 34.38592-78.72512 76.8 0 42.3936 35.24608 76.8 78.72512 76.8z m446.07488-153.6c43.47904 0 78.72512 34.38592 78.72512 76.8 0 42.3936-35.2256 76.8-78.72512 76.8-43.45856 0-78.72512-34.4064-78.72512-76.8 0-42.41408 35.26656-76.8 78.72512-76.8zM443.904 293.82656a24.7808 24.7808 0 0 1 34.73408 0c9.6256 9.46176 9.6256 24.82176 0 34.304l-48.59904 47.9232h57.40544c13.55776 0 24.55552 10.8544 24.55552 24.22784s-10.99776 24.22784-24.55552 24.22784H413.696v48.45568h73.74848c13.55776 0 24.55552 10.8544 24.55552 24.22784 0 13.39392-10.99776 24.22784-24.55552 24.22784H413.696v27.79136c0 6.43072-2.58048 12.57472-7.18848 17.14176-4.608 4.52608-10.8544 7.08608-17.36704 7.08608-6.5536 0-12.8-2.56-17.408-7.08608a24.08448 24.08448 0 0 1-7.18848-17.14176v-27.7504h-73.74848c-13.55776 0-24.55552-10.8544-24.55552-24.24832 0-13.37344 10.99776-24.22784 24.55552-24.22784H364.544v-48.47616h-73.74848c-13.55776 0-24.55552-10.8544-24.55552-24.22784 0-13.39392 10.99776-24.24832 24.55552-24.24832h57.1392l-48.59904-47.9232a24.064 24.064 0 0 1 0.8192-33.50528 24.82176 24.82176 0 0 1 33.93536-0.77824l54.90688 54.14912z"
      p-id="14394"
      fill={color}
    ></path>
  </svg>
);

export default ShippingCart;
