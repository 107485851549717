import React from "react";
const JCB = ({ viewBox = "0 0 24 24", size = 24, ...props }) => (
  <svg
    viewBox="0 0 1600 1024"
    p-id="46072"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1417.536 796.704a193.472 193.472 0 0 1-193.504 193.472H173.76V225.472A193.472 193.472 0 0 1 367.232 32h1050.304v764.704z"
      fill="#FFFFFF"
      p-id="46073"
    ></path>
    <path
      d="M1075.072 600.8h80c2.528 0 7.264-0.416 9.536-0.896 15.36-3.328 28.16-16.96 28.16-35.52 0-18.56-12.8-32.256-28.16-35.584a59.52 59.52 0 0 0-9.536-0.896h-80v72.896z"
      fill="#209348"
      p-id="46074"
    ></path>
    <path
      d="M1145.728 96.48a138.208 138.208 0 0 0-138.208 138.208v143.552h195.552c4.544 0 10.08 0.32 13.536 0.48 44.256 2.24 76.896 24.96 76.896 64.416 0 31.04-22.304 57.504-62.912 63.488v1.536c44.864 3.136 79.2 27.808 79.2 66.688 0 42.08-37.696 69.12-87.936 69.12h-214.336v281.728h202.72a138.208 138.208 0 0 0 138.176-138.208V96.48h-202.688z"
      fill="#209348"
      p-id="46075"
    ></path>
    <path
      d="M1183.296 453.12c0-18.24-12.8-30.496-27.84-33.024a62.016 62.016 0 0 0-7.872-0.64h-72.512v67.296h72.512c2.624 0 6.464-0.352 7.872-0.608 15.04-2.56 27.84-14.784 27.84-33.024z"
      fill="#209348"
      p-id="46076"
    ></path>
    <path
      d="M381.024 96.48A138.208 138.208 0 0 0 242.88 234.688v341.28c38.72 18.784 78.784 31.04 119.552 31.04 47.776 0 74.016-29.184 74.016-68.448v-160.32h118.848v160.32c0 62.72-38.464 113.024-170.56 113.024-79.52 0-141.888-17.152-141.888-17.152v291.264h202.72a138.208 138.208 0 0 0 138.176-138.208V96.48h-202.688z"
      fill="#0D66AE"
      p-id="46077"
    ></path>
    <path
      d="M763.36 96.48a138.208 138.208 0 0 0-138.176 138.208v180.928c35.008-30.048 95.552-48.8 193.92-44.384 52.672 2.368 108.512 16.48 108.512 16.48v58.496c-27.936-14.144-61.44-27.264-104.64-30.56-74.656-5.632-119.584 30.848-119.584 95.456 0 64.576 44.928 101.056 119.584 95.36 43.2-3.2 77.152-16.704 104.64-30.496v58.464s-55.84 14.144-108.48 16.512c-98.4 4.448-158.944-14.336-193.952-44.384v319.104h202.688a138.208 138.208 0 0 0 138.208-138.176V96.48h-202.72z"
      fill="#DB1C44"
      p-id="46078"
    ></path>
  </svg>
);
export default JCB;
