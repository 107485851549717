import React, { Component } from "react";
import "../../style/common/headBar.scss";
import logo from "../../image/logo-red.svg";
import AllowLeft from "../../icon/AllowLeft";

class HeadBar extends Component {
  constructor(props) {
    super(props);
    this.back = this.back.bind(this);
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  render() {
    return (
      <React.Fragment>
        <div className="header-box">
          <AllowLeft size={20} color="#EA0429" onClick={this.back} className="allowLeft"/>
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="right"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeadBar;
