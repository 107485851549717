import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../style/checkout.scss";

export default function CheckoutForm({ userDetails, url }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      handleVerification(paymentIntent);
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: url,
        payment_method_data: {
          billing_details: userDetails,
        },
      },
    });

    if (error) {
      handlePaymentError(error);
    } else if (
      paymentIntent.status === "requires_action" ||
      paymentIntent.status === "requires_source_action"
    ) {
      handleVerification(paymentIntent);
    } else if (paymentIntent.status === "succeeded") {
      setMessage("Payment succeeded!");
    } else {
      setMessage("Something went wrong.");
    }

    setIsLoading(false);
  };

  const handlePaymentError = (error) => {
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else if (error.type === "invalid_request_error") {
      setMessage("Invalid request. Please check the payment details.");
    } else if (error.type === "api_error") {
      setMessage(
        "Payment server is currently unavailable. Please try again later."
      );
    } else {
      setMessage("An unexpected error occurred.");
    }

    // Retry logic
    if (retryCount < 3) {
      setRetryCount(retryCount + 1);
      handleSubmit(new Event("submit")); // Retry submission
    } else {
      setMessage(
        "Payment failed after multiple attempts. Please try again later."
      );
    }
  };

  const handleVerification = async (paymentIntent) => {
    const nextAction = paymentIntent.next_action;

    if (nextAction) {
      switch (nextAction.type) {
        case "use_stripe_sdk":
          const { error } = await stripe.handleCardAction(
            paymentIntent.client_secret
          );
          if (error) {
            setMessage("Verification failed. Please try again.");
          } else {
            setMessage("Payment verification succeeded!");
          }
          break;
        case "redirect_to_url":
          window.location.href = nextAction.redirect_to_url.url;
          break;
        case "pay_by_link":
          window.location.href = nextAction.pay_by_link.payment_link;
          break;
        case "authorize_with_bank":
          window.location.href =
            nextAction.authorize_with_bank.bank_authorization_url;
          break;
        default:
          setMessage("Unsupported verification action.");
          break;
      }
    } else {
      setMessage("No additional action required.");
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
    fields: {
      billingDetails: {
        name: "never",
        email: "never",
        address: {
          city: "never",
          state: "never",
          country: "never",
          postalCode: "never",
        },
      },
    },
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className={`form ${isLoading ? "form-disabled" : ""}`}
    >
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
