import React from "react";

const AllowLeft = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="30993"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M402.752512 146.752512l-320 320c-24.992768 24.991744-24.992768 65.503232 0 90.496l320 320c24.991744 24.991744 65.503232 24.991744 90.496 0 24.991744-24.992768 24.991744-65.50528 0-90.496L282.496 576H896c35.359744 0 64-28.640256 64-64s-28.640256-64-64-64H282.496l210.752512-210.752512C505.728 224.73728 512 208.384 512 192s-6.240256-32.768-18.752512-45.248512c-24.991744-24.991744-65.503232-24.991744-90.496 0z"
      fill={color}
      p-id="30994"
    ></path>
  </svg>
);

export default AllowLeft;
