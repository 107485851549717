import React from "react";

const ShoppingCart = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="3491"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M298.666667 768c-46.933333 0-85.333333 38.4-85.333333 85.333333s38.4 85.333333 85.333333 85.333333c46.933333 0 85.333333-38.4 85.333333-85.333333S345.6 768 298.666667 768zM42.666667 85.333333l0 85.333333 85.333333 0 153.6 324.266667L221.866667 597.333333C217.6 610.133333 213.333333 627.2 213.333333 640c0 46.933333 38.4 85.333333 85.333333 85.333333l512 0 0-85.333333L315.733333 640c-4.266667 0-8.533333-4.266667-8.533333-8.533333 0 0 0-4.266667 0-4.266667L345.6 554.666667l315.733333 0c34.133333 0 59.733333-17.066667 72.533333-42.666667l153.6-277.333333C896 226.133333 896 221.866667 896 213.333333c0-25.6-17.066667-42.666667-42.666667-42.666667L221.866667 170.666667 183.466667 85.333333 42.666667 85.333333zM725.333333 768c-46.933333 0-85.333333 38.4-85.333333 85.333333s38.4 85.333333 85.333333 85.333333c46.933333 0 85.333333-38.4 85.333333-85.333333S772.266667 768 725.333333 768z"
      fill={color}
      p-id="3492"
    ></path>
  </svg>
);


export default ShoppingCart;