import React, { Component } from "react";
import "../style/model.scss";
import HeadBar from "./common/HeadBar";
import i5M60 from "../image/bmw-i5-m60.png";
import ixM60 from "../image/bmw-ix-m60.png";
import i4 from "../image/bmw-i4.png";
import bmw3 from "../image/bmw3.png";
import * as FackbookAPI from "../apis/FacebookAPI";
import img1 from "../image/1.jpeg";
import img2 from "../image/2.jpeg";
import img3 from "../image/3.jpeg";
import img4 from "../image/4.jpeg";
import img5 from "../image/5.jpeg";
import water from "../image/water.jpg";
import mian from "../image/mian.jpg";
import T_shirt from "../image/T-shirt.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper/core";
import { Navigation, Pagination, Autoplay, Thumbs } from "swiper/modules";

import AddIcon from "../icon/AddIcon";
import SubIcon from "../icon/SubIcon";
import s1 from "../image/s1.jpg";
import s2 from "../image/s2.jpg";
import s3 from "../image/s3.jpg";
import s4 from "../image/s4.jpg";
import s5 from "../image/s5.png";
import s6 from "../image/s6.png";
import s7 from "../image/s7.png";
import s8 from "../image/s8.jpeg";
import s9 from "../image/s9.jpeg";

import CloseIcon from "../icon/CloseIcon";
import O1 from "../image/O1.webp";
import O2 from "../image/O2.webp";
import wq1 from "../image/wq1.webp";
import wq2 from "../image/wq2.webp";
import payment from "../image/payment.webp";
import fedexShip from "../image/fedex-ship.webp";
import rollover1 from "../image/rollover1.webp";
import rollover2 from "../image/rollover2.webp";
import rollover3 from "../image/rollover3.webp";

import silver1 from "../image/silver1.webp";
import silver2 from "../image/silver2.webp";
import silver3 from "../image/silver3.webp";
import silver4 from "../image/silver4.webp";
import silver5 from "../image/silver5.webp";
import silver6 from "../image/silver6.webp";
import silver7 from "../image/silver7.webp";
import silver8 from "../image/silver8.webp";

SwiperCore.use([Navigation, Thumbs]);

class Model extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartList: [
        {
          title: "Acheter 1",
          choose: true,
          img: silver1,
          disabled: false,
        },
        {
          title: "🔥 Vente Flash - Achetez 1, obtenez 1 GRATUIT",
          choose: false,
          img: silver1,
          disabled: false,
        },
        {
          title: "🔥 Ensemble d'amis - Achetez-en 2 et obtenez-en 2 GRATUITS",
          choose: false,
          img: silver1,
          disabled: false,
        },
      ],

      img: [
        silver1,
        silver2,
        silver3,
        silver4,
        silver5,
        silver6,
        silver7,
        silver8,
      ],
      // vehicleModel: "Buy 1",
      // vehicleModel2: "Light Pale",
      // vehicleModel3: "A (Fits 34AA - 32A - 30B)",
      thumbsSwiper: null,
      num: 1,
      descrption: false,
      shipping: false,
      returning: false,
      price: 285.0,
      title: "1OZ SUNSHINE MINTING Encapsulated Silver Bar",
      sizeModelShow: null,
      scrollTop: 0,
    };
    this.changeSelect = this.changeSelect.bind(this);
    this.routeCustomer = this.routeCustomer.bind(this);
    this.setThumbsSwiper = this.setThumbsSwiper.bind(this);
    this.changeNum = this.changeNum.bind(this);
    this.descrptionShow = this.descrptionShow.bind(this);
    this.shippingShow = this.shippingShow.bind(this);
    this.returningShow = this.returningShow.bind(this);
    this.changeSelect2 = this.changeSelect2.bind(this);
    this.changeSelect3 = this.changeSelect3.bind(this);
    this.SizeModelShow = this.SizeModelShow.bind(this);
    this.changeModel = this.changeModel.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.someState !== this.state.someState) {
  //     // 执行操作
  //     window.scrollTo(0, 0);
  //   }
  // }

  changeSelect(index) {
    let { cartList } = this.state;
    cartList.map((item, i) => {
      item.choose = false;
      if (i === index) {
        item.choose = true;
        this.setState({ vehicleModel: item.title });
        // fbq;
        if (window.fbq) {
          window.fbq("track", "CustomizeProduct", {
            content_name: item.title,
            content_ids: [item.title],
          });
          let customdata = {
            content_name: item.title,
            content_ids: [item.title],
          };
          let userdata = {
            em: "",
            ph: "",
            fn: "",
            ln: "",
            ct: "",
            st: "",
            zp: "",
            country: "",
            external_id: "",
            client_ip_address: "",
            client_user_agent: "",
            fbc: "",
            fbp: "",
            lead_id: "",
            anon_id: "",
            madid: "",
            page_id: "",
            page_scoped_user_id: "",
            ctwa_clid: "",
            ig_account_id: "",
            ig_sid: "",
          };
          let event_id = Math.round(Math.random() * 10000000000);
          FackbookAPI.Facebook(
            event_id,
            "CustomizeProduct",
            customdata,
            userdata
          ).then((res) => {
            console.log(res);
          });
        }
      }
    });
    this.setState({ cartList });
  }

  changeSelect2(index) {
    let { cartList2 } = this.state;
    cartList2.map((item, i) => {
      item.choose = false;
      if (i === index) {
        item.choose = true;
        this.setState({ vehicleModel2: item.title });
        // fbq;
        if (window.fbq) {
          window.fbq("track", "CustomizeProduct", {
            content_name: item.title,
            content_ids: [item.title],
          });
          let customdata = {
            content_name: item.title,
            content_ids: [item.title],
          };
          let userdata = {
            em: "",
            ph: "",
            fn: "",
            ln: "",
            ct: "",
            st: "",
            zp: "",
            country: "",
            external_id: "",
            client_ip_address: "",
            client_user_agent: "",
            fbc: "",
            fbp: "",
            lead_id: "",
            anon_id: "",
            madid: "",
            page_id: "",
            page_scoped_user_id: "",
            ctwa_clid: "",
            ig_account_id: "",
            ig_sid: "",
          };
          let event_id = Math.round(Math.random() * 10000000000);
          FackbookAPI.Facebook(
            event_id,
            "CustomizeProduct",
            customdata,
            userdata
          ).then((res) => {
            console.log(res);
          });
        }
      }
    });
    this.setState({ cartList2 });
  }

  changeSelect3(index) {
    let { cartList3 } = this.state;
    cartList3.map((item, i) => {
      item.choose = false;
      if (i === index) {
        item.choose = true;
        this.setState({ vehicleModel3: item.title });
        // fbq;
        if (window.fbq) {
          window.fbq("track", "CustomizeProduct", {
            content_name: item.title,
            content_ids: [item.title],
          });
          let customdata = {
            content_name: item.title,
            content_ids: [item.title],
          };
          let userdata = {
            em: "",
            ph: "",
            fn: "",
            ln: "",
            ct: "",
            st: "",
            zp: "",
            country: "",
            external_id: "",
            client_ip_address: "",
            client_user_agent: "",
            fbc: "",
            fbp: "",
            lead_id: "",
            anon_id: "",
            madid: "",
            page_id: "",
            page_scoped_user_id: "",
            ctwa_clid: "",
            ig_account_id: "",
            ig_sid: "",
          };
          let event_id = Math.round(Math.random() * 10000000000);
          FackbookAPI.Facebook(
            event_id,
            "CustomizeProduct",
            customdata,
            userdata
          ).then((res) => {
            console.log(res);
          });
        }
      }
    });
    this.setState({ cartList3 });
  }

  routeCustomer() {
    let { history, location } = this.props;
    let { vehicleModel, vehicleModel2, vehicleModel3, num, price, title, img } =
      this.state;
    let orderDetail = {};
    orderDetail.title = title;
    orderDetail.img = img;
    orderDetail.vehicleModel = vehicleModel;
    orderDetail.vehicleModel2 = vehicleModel2;
    orderDetail.vehicleModel3 = vehicleModel3;
    orderDetail.num = num;
    orderDetail.amount = (num * price).toFixed(2);
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    const search = location.search;
    history &&
      history.push({
        pathname: "/Customer",
        search: location.search,
      });
    // fbq;
    // if (window.fbq) {
    //   let event_id = Math.round(Math.random() * 10000000000);
    //   window.fbq(
    //     "track",
    //     "AddToCart",
    //     {
    //       content_name: vehicleModel,
    //       content_ids: [vehicleModel],
    //       currency: "EUR",
    //       content_type: "T-shirt",
    //       content_category: "T-shirt",
    //       contents: [
    //         {
    //           title: vehicleModel,
    //           id: vehicleModel,
    //           quantity: orderDetail.num,
    //         },
    //       ],
    //       value: orderDetail.total,
    //     },
    //     { eventID: event_id }
    //   );
    //   let customdata = {
    //     content_name: vehicleModel,
    //     content_ids: [vehicleModel],
    //     currency: "EUR",
    //     content_type: "T-shirt",
    //     content_category: "T-shirt",
    //     contents: [
    //       {
    //         title: vehicleModel,
    //         id: vehicleModel,
    //         quantity: orderDetail.num,
    //       },
    //     ],
    //     value: orderDetail.amount,
    //   };
    //   let userdata = {
    //     em: "",
    //     ph: "",
    //     fn: "",
    //     ln: "",
    //     ct: "",
    //     st: "",
    //     zp: "",
    //     country: "",
    //     external_id: "",
    //     client_ip_address: "",
    //     client_user_agent: "",
    //     fbc: "",
    //     fbp: "",
    //     lead_id: "",
    //     anon_id: "",
    //     madid: "",
    //     page_id: "",
    //     page_scoped_user_id: "",
    //     ctwa_clid: "",
    //     ig_account_id: "",
    //     ig_sid: "",
    //   };
    //   FackbookAPI.Facebook(event_id, "AddToCart", customdata, userdata).then(
    //     (res) => {
    //       console.log(res);
    //     }
    //   );
    // }
  }

  setThumbsSwiper = (swiper) => {
    console.log(swiper);
    this.setState({ thumbsSwiper: swiper });
  };

  changeNum(type) {
    let { num } = this.state;
    switch (type) {
      case "sub":
        if (num <= 1) {
          return;
        }
        num--;
        this.setState({ num });
        break;
      case "add":
        num++;
        this.setState({ num });
        break;
      default:
        break;
    }
  }

  descrptionShow() {
    this.setState({ descrption: !this.state.descrption });
  }

  shippingShow() {
    this.setState({ shipping: !this.state.shipping });
  }

  returningShow() {
    this.setState({ returning: !this.state.returning });
  }

  SizeModelShow() {
    this.setState({ sizeModelShow: !this.state.sizeModelShow });
  }

  changeModel() {
    var sizeModel = document.querySelector(".sizeModel");
    sizeModel.classList.add("sizeModel-hidden");
    setTimeout(() => {
      this.SizeModelShow();
    }, 1000);
  }

  render() {
    const {
      cartList,
      img,
      num,
      select,
      descrption,
      shipping,
      returning,
      price,
      title,
      sizeModelShow,
    } = this.state;
    return (
      <React.Fragment>
        <div className={`sizeModel ${sizeModelShow ? "sizeModel-show" : ""}`}>
          <div className="sizeModel-core">
            <div className="header">
              Guide des tailles
              <CloseIcon size={30} onClick={this.changeModel} />
            </div>
            <div className="body-contain">
              <div className="img-box">
                <img src={s9} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="model-page">
          <div className="header">DÉSTOCKAGE + LIVRAISON GRATUITE 🇫🇷</div>
          {/* <div className="logo-bar">Brareois</div> */}
          <div className="model-box">
            <div className="choose-box">
              <div className="right">
                <Swiper
                  className="mySwiper"
                  thumbs={{ swiper: this.state.thumbsSwiper }}
                >
                  {img.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <img src={item} alt="" />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                {/* <div className="myThumbs">
                  <Swiper
                    className="mySwiperThumbs"
                    onSwiper={this.setThumbsSwiper}
                    spaceBetween={20}
                    slidesPerView={5}
                    freeMode={true}
                    watchSlidesProgress={true}
                  >
                    {img.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <img src={item} alt="" />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div> */}
              </div>
              <div className="left">
                <div className="title">{title}</div>
                <div className="star">⭐⭐⭐⭐⭐ 8,589+ CLIENTS SATISFAITS</div>
                <div className="price">
                  {/* <div className="title">PRIX</div> */}
                  <div className="value">
                    <div className="current">${price.toFixed(2)}</div>
                    <div className="original">$140.00</div>
                    <div className="save">Save 50%</div>
                  </div>
                </div>
                <div className="ship-txt">
                  <div className="ship-li">
                    <p>✅ Livraison à partir du 21 Août</p>
                    <p>✅ En stock en boutique</p>
                    <br />
                    <p> Diamètre : 18mm</p>
                    <p> Métal : OR FIN 999‰ </p>
                    <p> Poids : 3g</p>
                    <p> Qualité : Brillant Universel</p>
                    <p> Tirage : 15000</p>
                    <p> Millésime : 2021</p>
                    <p> Valeur faciale : 250€</p>
                    <br />
                    <p>
                      Cliquez ici pour découvrir les collections Paris 2024 en
                      vidéo !A l’occasion des Jeux d’été de Paris 2024, la
                      Monnaie de Paris rend hommage à cette compétition
                      internationale de renom au travers d’une collection
                      mettant à l’honneur le sport, ses v...
                    </p>
                    <br />
                    <a href="#product_detail" className="href">
                      Lire la suite
                    </a>
                  </div>
                </div>

                <div className="choose-title">L'offre groupée</div>
                <div className="choose-row">
                  {cartList.map((item, index) => {
                    return (
                      <div
                        className={`li-div ${item.disabled ? "disabled" : ""} `}
                        key={index}
                      >
                        <li
                          className={item.choose ? "active" : ""}
                          onClick={() => this.changeSelect(index)}
                        >
                          {item.title}
                        </li>
                      </div>
                    );
                  })}
                </div>
                {/* <div className="choose-title" onClick={this.SizeModelShow}>
                  <Size size={30} /> <span>Guide des tailles</span>
                </div> */}
                <div className="quantity">
                  <div className="title">QUANTITÉ</div>
                  <div className="numberBar">
                    <div className="sub" onClick={() => this.changeNum("sub")}>
                      <SubIcon size={18} />
                    </div>
                    <div className="num">{num}</div>
                    <div className="add" onClick={() => this.changeNum("add")}>
                      <AddIcon size={18} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn" onClick={this.routeCustomer}>
              ACHETER
            </div>
            <div className="box">
              <img src={payment} alt="" />
              <img src={fedexShip} alt="" />
            </div>
          </div>
          {/* <div className="descrption-box">
            <div
              className={`descrption-row ${
                descrption ? "descrption-border" : ""
              }`}
              onClick={this.descrptionShow}
            >
              <p></p>Détail
              {descrption ? <SubIcon size={20} /> : <AddIcon size={20} />}
            </div>
            <div
              className={`descrption-content ${
                descrption ? "descrption-show" : ""
              }`}
            >
              <div className="table">
                <img src={s9} alt="" />
              </div>
            </div>
          </div> */}
          {/* <div className="shipping-box">
            <div
              className={`shipping-row ${shipping ? "shipping-border" : ""}`}
              onClick={this.shippingShow}
            >
              <p></p>EXPÉDITION
              {shipping ? <AllowUp size={14} /> : <AllowDown size={14} />}
            </div>
            <div
              className={`shipping-content ${shipping ? "shipping-show" : ""}`}
            >
              <p>
                Nous expédions dans le monde entier et le délai de livraison est
                de 5 à 10 jours ouvrables. en fonction de l'endroit où vous vous
                trouvez.
              </p>
              <p>États-Unis - 5-8 jours ouvrables</p>
              <p>Royaume-Uni - 5-7 jours ouvrables</p>
              <p>AU/NZ - 6-10 business days</p>
              <p>Royaume-Uni - 5-7 jours ouvrables</p>
              <p>
                Une fois le colis expédié, vous recevrez un numéro de suivi qui
                vous permettra de vous tenir au courant de l'état de la
                livraison. l'état d'avancement de la livraison.
              </p>
            </div>
          </div> */}
          {/* <div className="return-box">
            <div
              className={`return-row ${returning ? "return-border" : ""}`}
              onClick={this.returningShow}
            >
              <p></p>RETOUR
              {returning ? <AllowUp size={14} /> : <AllowDown size={14} />}
            </div>
            <div className={`return-content ${returning ? "return-show" : ""}`}>
              <p>
                Notre police a une durée de 30 jours. Si 30 jours se sont
                écoulés depuis votre l'achat, nous ne pouvons malheureusement
                pas vous offrir un échange.
              </p>
              <p>
                Pour pouvoir être retourné, votre article doit être inutilisé et
                dans le même état que vous l'avez reçu. dans le même état que
                celui dans lequel vous l'avez reçu. Il doit également être dans
                son l'emballage d'origine.
              </p>
            </div>
          </div> */}
          <div className="wrapper" id="product_detail">
            <h2>Description</h2>
            <p>
              <a href="https://youtu.be/tloG3VOtR8s">
                Cliquez ici pour découvrir les collections Paris 2024 en vidéo !
              </a>
            </p>
            <p>
              A l’occasion des Jeux d’été de Paris 2024, la Monnaie de Paris
              rend hommage à cette compétition internationale de renom au
              travers d’une collection mettant à l’honneur le sport, ses
              valeurs, les athlètes mais aussi le patrimoine du pays hôte.
              Olympisme et Paralympisme sont célébrés sur ces produits, frappés
              en France dans nos ateliers d’Art.
            </p>
            <p>
              Suite au report des Jeux Olympiques de Tokyo, l’année 2021 marque
              officiellement le début du compte à rebours des Jeux de Paris.
              C’est la passation du drapeau olympique lors de la cérémonie de
              clôture des Jeux qui marque le point de départ d’une nouvelle ère
              de Jeux à la Française. Ce transfert de flambeau sera mis à
              l’honneur dans cette collection.2021 est aussi la première année
              d’une quadrilogie ou le sport et ses valeurs seront présentées au
              travers des collections de monnaies et de médaillons de la Monnaie
              de Paris. Un tel évènement est l'occasion pour la Monnaie de Paris
              de continuer à se renouveler pour offrir des produits toujours
              plus beaux et innovant. Pour la première fois, nos presses
              frappent des monnaies de collection hexagonales, en hommage au
              territoire Français.
            </p>
            <p>
              La face de ces monnaies présente Marianne en pleine course,
              surplombant le stade d’athlétisme des Jeux. On retrouve également
              la tour Eiffel en fond symbolisant ici la ville de Paris.
            </p>
            <p>
              Ce dessin est également décliné sur les 2€ commémoratives,
              disponibles uniquement en version Belle Epreuve et Brillant
              Universel.
            </p>
          </div>
          <div className="wrapper">
            <h2>La collection</h2>
            <p>
              "La Monnaie de Paris célèbre les Jeux Olympiques et Paralympiques
              avec différentes collections innovantes et inédites sur plusieurs
              années mettant à l'honneur les sportifs et l'esprit olympique.
              Monnaies de collection, 2€ commémoratives et EURO or et argent ou
              encore médaillons, ces collections mettent à l'honneur les
              disciplines, les lieux et les icônes du patrimoine olympique."
            </p>
            <div className="box">
              <img src={rollover1} alt="" />
            </div>
            <div className="box">
              <img src={rollover2} alt="" />
            </div>
            <div className="box">
              <img src={rollover3} alt="" />
            </div>
          </div>
          <div className="wrapper">
            <h2>Les métiers de la monnaie</h2>
            <div className="box">
              <img src={wq1} alt="" />
            </div>
            <h2>LE GRAVEUR</h2>
            <p>
              Le graveur réalise avec talent un modèle en bas relief en pâte à
              modeler industrielle, puis en plâtre à partir de son dessin (main
              levée ou Dessin Assisté par Ordinateur) afin de magnifier sa
              gravure et l’accroche de la lumière. Il alterne le travail en
              creux et en relief jusqu’à un rendu optimal. Le graveur pratique
              les techniques ancestrales de la taille directe et pour laquelle
              il se fabrique ses propres outils.
            </p>
            <div className="box">
              <img src={wq2} alt="" />
            </div>
            <h2>LE MONNAYEUR</h2>
            <p>
              Le métier de monnayeur est apparu en Gaule avec la frappe au
              marteau au IVe siècle av. J-C. ! La presse a désormais remplacé le
              marteau. Le savoir-faire du monnayeur, sa connaissance des
              outillages, de la gravure et des métaux, son expertise, transmise
              de génération en génération sans discontinuer, restent les mêmes
              assurant l’excellence des productions
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Model;
