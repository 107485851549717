import React from "react";
const AddIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#444444",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="7652"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M927.07 469.31H554.66V96.94h-85.34v372.37H96.93v85.34h372.39v372.41h85.34V554.65h372.41z"
      fill={color}
      p-id="7653"
    ></path>
  </svg>
);

export default AddIcon;
