import api from "./API";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";

export const upload = (file) => {
  return api.post(`${ApiEndpointConstants.UPLOAD}`, { file });
};

export const uploadVideo = (file) => {
  return api.post(
    `${ApiEndpointConstants.UPLOAD}`,
    { file },
    // { headers: { "content-type": "multipart/form-data" } }
  );
};
