import React from "react";

const Kuang = ({
  viewBox = " 0 0 1578 1024",
  color = "#1678FF",
  size = 200,
  ...props
}) => (
  <svg
    viewBox="0 0 1578 1024"
    p-id="107245"
    width={size}
    height={size}
    {...props}
  >
    <path d="M106.666667 1024v-32H0v32z" fill={color} p-id="107246"></path>
    <path d="M0 1024h32V917.333333H0z" fill={color} p-id="107247"></path>
    <path d="M106.666667 0v32H0V0z" fill="#1A8CFF" p-id="107248"></path>
    <path d="M0 0h32v106.666667H0z" fill="#1A8CFF" p-id="107249"></path>
    <path
      d="M1578.666667 106.666667h-32V0h32z"
      fill={color}
      p-id="107250"
    ></path>
    <path
      d="M1578.666667 0v32h-106.666667V0z"
      fill={color}
      p-id="107251"
    ></path>
    <path
      d="M1578.666667 917.333333h-32v106.666667h32z"
      fill={color}
      p-id="107252"
    ></path>
    <path
      d="M1578.666667 1024v-32h-106.666667v32z"
      fill={color}
      p-id="107253"
    ></path>
  </svg>
);

export default Kuang;
