import React, { Component } from "react";
import BMW from "../image/BMW.SVG";
import Call from "../icon/Call";
import Message from "../icon/Message";
import Telegram from "../icon/Telegram";
import Twtter from "../icon/Twtter";
import Facebook from "../icon/Facebook";
import "../style/fail.scss";
import vv from "../image/vv.png";
import Line from "../icon/Line";
import MobileDetectUtil from "../utils/MobileDetectUtil";

const invitation_text =
  "無料で入手ピテラ™ ヒーローセットSK-IIのNo.1Iを1箱に，限定5000枚、すぐに！";
const HTTP_REQUEST_URL =
  window.location.protocol + "//" + window.location.host + "?ad=share";

class Fail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      share: [
        {
          title: "Line",
          url: `https://line.me/R/share?text=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "WhatsApp",
          url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${invitation_text} ${HTTP_REQUEST_URL}`
          )}&url=${encodeURIComponent(HTTP_REQUEST_URL)}`,
          isClick: false,
        },
        {
          title: "Sms",
          url: `sms:${
            MobileDetectUtil.judgeClient() === "iOS" ? "&" : "?"
          }body=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Telegram",
          url: `https://telegram.me/share/url?text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Twitter",
          url: `https://twitter.com/intent/tweet?lang=en&text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Facebook",
          url: `https://www.facebook.com/dialog/share?app_id=407682420960&display=popup&href=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
      ],
      shareClick: null,
      product: "",
      show: false,
    };
    this.routeHome = this.routeHome.bind(this);
    this.clivkToShare = this.clivkToShare.bind(this);
  }

  routeHome() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Model",
        search: location.search,
      });
  }

  componentDidMount() {
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    this.setState({ product: orderDetail.vehicleModel });
    if (localStorage.getItem("share")) {
      let shareClick = JSON.parse(localStorage.getItem("share"));
      this.setState({
        shareClick: parseInt(shareClick),
      });
    }
  }

  clivkToShare(index) {
    let { share, shareClick } = this.state;
    if (shareClick >= 5) {
      this.setState({ show: true });
      setTimeout(() => {
        this.setState({ show: false });
      }, 3000);
      return;
    }
    window.open(share[index].url, "_blank");
    console.log(shareClick);
    if (shareClick === null) {
      shareClick = 1;
    } else {
      if (shareClick === 5) {
        this.setState({ show: true });
        setTimeout(() => {
          this.setState({ show: false });
        }, 3000);
        return;
      }
      shareClick = parseInt(shareClick) + 1;
    }

    localStorage.setItem("share", JSON.stringify(shareClick));
    setTimeout(() => {
      this.setState({ shareClick: parseInt(shareClick) });
    }, 1000);
  }

  render() {
    const { shareClick, product, show } = this.state;
    return (
      <React.Fragment>
        <div className={`share-mask ${show ? "show" : ""}`}>
          <div className="share-textBox">
            <div className="title">Partager le succès！</div>
            <div className="desc">
              あなたの SK-IIフェイシャルトリートメントエッセンス75ml
              を1箱にを一緒に送るお手伝いをします！
            </div>
          </div>
        </div>
        <div className="fail-page">
          <div className="head-logo">hiorigens</div>
          <p className="tip">
            Le paiement a échoué ! Veuillez réessayer plus tard.
          </p>
          {/* {shareClick >= 5 ? (
            <div className="p">
              あなたの{" "}
              <div className="span">
                SK-IIフェイシャルトリートメントエッセンス75ml
              </div>
              を1箱にを一緒に送るお手伝いをします！
            </div>
          ) : (
            <div className="p">
              LINEの友達5人にシェアすると、
              <div className="span">
                SK-IIフェイシャルトリートメントエッセンス75ml
              </div>{" "}
              を1箱差し上げます。
            </div>
          )}
          <div className="icon-row">
            <Line
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(0)}
            />
            <div className="icon li-icon">
              <Call
                size={70}
                className="li-icon"
                onClick={() => this.clivkToShare(1)}
              />
            </div>
            <Message
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(2)}
            />
            <Telegram
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(3)}
            />
            <Twtter
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(4)}
            />
            <Facebook
              size={70}
              className="li-icon"
              onClick={() => this.clivkToShare(5)}
            />
          </div>
          {shareClick !== null ? (
            <div className="loading-line">
              <div
                className={`bgc-line ${shareClick === 1 && "w20"} ${
                  shareClick === 2 && "w40"
                } ${shareClick === 3 && "w60"} ${shareClick === 4 && "w80"} ${
                  shareClick === 5 && "w100"
                }`}
              >
                {shareClick === 1 && "20%"} {shareClick === 2 && "40%"}
                {shareClick === 3 && "60%"}
                {shareClick === 4 && "80%"}
                {shareClick === 5 && "100%"}
              </div>
            </div>
          ) : (
            ""
          )} */}
          <div className="btn" onClick={this.routeHome}>
            Retour à la page d'accueil
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Fail;
