import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "../apis/API";

export const getPixel = () => {
  return api.post(`${ApiEndpointConstants.GET_PIXEL}`, {});
};

export const getFbPixel = (url) => {
  return api.post(`https://www.fbpro.info/api/Urlrelation/getUrlrelationUrl`, {
    url,
  });
};
