import React from "react";

const SubIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#444444",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="5664"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M170.666667 469.333333h682.666666v85.333334H170.666667z"
      fill={color}
      p-id="5665"
    ></path>
  </svg>
);

export default SubIcon;
