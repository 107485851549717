import React from "react";
const Photo = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#000",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="131769"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M690.24 563.52c0 96.448-78.144 174.592-174.592 174.592s-174.656-78.144-174.656-174.592S419.2 388.928 515.648 388.928s174.592 78.144 174.592 174.592z"
      fill={color}
      p-id="131770"
    ></path>
    <path
      d="M690.24 563.52c0 96.448-78.144 174.592-174.592 174.592s-174.656-78.144-174.656-174.592S419.2 388.928 515.648 388.928s174.592 78.144 174.592 174.592z"
      fill={color}
      p-id="131771"
    ></path>
    <path
      d="M690.24 563.52c0 96.448-78.144 174.592-174.592 174.592s-174.656-78.144-174.656-174.592S419.2 388.928 515.648 388.928s174.592 78.144 174.592 174.592z"
      fill={color}
      p-id="131772"
    ></path>
    <path
      d="M947.2 216.704h-170.048c-18.496 0-33.536-15.04-33.536-33.536 0-42.24-34.56-76.8-76.8-76.8H364.416c-42.24 0-76.8 34.56-76.8 76.8 0 18.496-15.04 33.536-33.536 33.536H76.8c-42.24 0-76.8 34.56-76.8 76.8v547.264c0 42.24 34.56 76.8 76.8 76.8h870.4c42.24 0 76.8-34.56 76.8-76.8V293.504c0-42.24-34.56-76.8-76.8-76.8z m-435.2 583.36c-128.576 0-232.832-104.256-232.832-232.832 0-128.576 104.256-232.832 232.832-232.832s232.832 104.256 232.832 232.832c0 128.512-104.256 232.832-232.832 232.832z m334.592-454.784a30.304 30.304 0 1 1-0.064-60.608 30.304 30.304 0 0 1 0.064 60.608z"
      fill={color}
      p-id="131773"
    ></path>
  </svg>
);

export default Photo;
