import React from "react";

const Hot = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#D81E06",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="43398"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M360.555789 955.122526c-55.457684-114.984421-26.004211-181.086316 16.545685-243.065263 46.565053-67.826526 58.772211-135.221895 58.77221-135.221895s36.378947 47.562105 21.989053 121.882948c64.538947-72.165053 76.907789-186.421895 67.125895-230.4 146.458947 102.049684 208.842105 323.098947 124.335157 486.80421 448.296421-253.116632 111.535158-631.700211 53.032422-674.681263 19.402105 42.981053 22.986105 114.984421-16.249264 150.069895-66.667789-252.820211-231.828211-304.720842-231.82821-304.720842 19.536842 130.506105-71.006316 272.976842-157.965474 379.742316-3.287579-51.873684-6.467368-87.956211-33.899789-137.701053-6.197895 94.450526-78.632421 171.627789-98.034527 266.186105-26.300632 128.215579 19.833263 221.938526 196.176842 321.104842z"
      fill={color}
      p-id="43399"
    ></path>
  </svg>
);

export default Hot;
