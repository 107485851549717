import React from "react";
const People = ({
  viewBox = "0 0 1068 1024",
  size = 200,
  color = "#dbdbdb",
  ...props
}) => (
  <svg
    viewBox="0 0 1068 1024"
    p-id="117310"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M979.922837 814.395865L743.839877 708.799882a249.003959 249.003959 0 0 1-62.54799-33.215994c-11.257998-10.543998-10.464998-35.515994-0.476-67.383989S811.779865 446.795926 811.779865 329.071945C811.779865 147.293976 686.999886 0 532.967912 0S254.235958 147.372976 254.235958 329.150945c0 112.729981 107.814982 247.101959 121.13298 271.518955a77.451987 77.451987 0 0 1-0.476 72.932988 241.07596 241.07596 0 0 1-73.487988 33.929995L88.550985 815.822865A125.729979 125.729979 0 0 0 0 928.392846v59.06099C0 1023.28683 46.930992 1023.99983 104.563983 1023.99983h859.503857c57.63299 0 104.563983 0.396 104.563983-36.624994v-59.05999a127.315979 127.315979 0 0 0-88.709986-113.997981z"
      p-id="117311"
      fill={color}
      fillOpacity={0.5}
    ></path>
  </svg>
);

export default People;
