import React from "react";

const Visa = ({ viewBox = "0 0 24 24", size = 24, ...props }) => (
  <svg
    viewBox="0 0 3279 1024"
    version="1.1"
    p-id="13282"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1154.778975 1008.864672L1320.699423 17.357569h265.455628l-166.091323 991.507103h-264.857563M2382.607375 41.707377a674.959596 674.959596 0 0 0-237.859179-41.608269c-262.294425 0-447.011216 134.479292-448.549099 327.227247-1.537883 142.510457 131.830716 222.138601 232.47659 269.471211 103.29445 48.528741 138.067685 79.628145 137.555057 123.03061-0.683503 66.470705-82.447596 96.801167-158.743662 96.801168a548.340594 548.340594 0 0 1-249.820488-52.031696l-34.17517-15.720578-37.250935 222.138602a826.355597 826.355597 0 0 0 295.615216 52.971512c279.040258 0 460.083219-132.941409 462.133729-338.761367 0.939817-112.863497-69.717346-198.643172-222.822105-269.471211-92.785585-45.794727-149.601804-76.894131-149.003739-122.945172 0-41.266517 48.101551-85.437924 151.994066-85.437923a481.699013 481.699013 0 0 1 198.557735 38.019876l23.83718 11.448681 35.969366-214.961815m680.513062-23.495429h-205.051017a131.65984 131.65984 0 0 0-138.922064 82.191282l-394.125141 908.888631h278.698507s45.538413-122.176231 55.876402-149.089176l339.87206 0.512627c7.945727 34.687797 32.295535 148.576549 32.295535 148.576549h246.232096L3063.205875 18.382824m-327.312686 639.161106c21.872108-57.072533 105.686711-277.160624 105.686712-277.160624-1.537883 2.563138 21.786671-57.414285 35.114986-94.665219l17.941964 85.437923 61.515305 286.302482h-220.258967M932.213184 17.784758L672.311021 694.025923l-27.59645-137.469619a768.941312 768.941312 0 0 0-367.383071-415.911812l237.517428 867.194925h280.749016L1213.303953 17.784758h-281.090769"
      fill="#1A1F71"
      p-id="13283"
    ></path>
    <path
      d="M431.290638 17.443006H3.417517L0 37.948108c333.207902 82.105845 553.295993 280.492703 644.714571 518.86451L551.672673 101.086734a111.069301 111.069301 0 0 0-120.467473-83.643728"
      fill="#1A1F71"
      p-id="13284"
    ></path>
  </svg>
);

export default Visa;
