import React from "react";

const SolidAllowDown = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="4488"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M219.428571 365.714286l292.571429 365.714285 292.571429-365.714285H219.428571z"
      fill={color}
      p-id="4489"
    ></path>
  </svg>
);

export default SolidAllowDown;
