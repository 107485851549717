import React from "react";

const AllowsLeft = ({
  viewBox = "0 0 24 24",
  color = "#333",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="29948"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M675.584 933.274c8.5 8.499 19.507 13.516 32.512 13.516 26.01 0 45.517-19.456 45.517-45.516a47.462 47.462 0 0 0-13.005-32.512L376.576 512.768l364.032-355.994a48.435 48.435 0 0 0 13.005-32.512 44.851 44.851 0 0 0-45.517-45.977 44.902 44.902 0 0 0-32.512 13.517l-395.98 387.43a43.93 43.93 0 0 0-15.002 33.485c0 12.544 5.017 24.064 14.49 33.536l396.492 386.97z"
      fill={color}
      p-id="29949"
    ></path>
  </svg>
);


export default AllowsLeft;